import React from "react";
import FeatureCard from "./FeatureCard";

import SubBrandsSlides from "./SubBrandsSlides";

import { useQuery } from "react-query";
import { getPageData } from "../services/dataServices";
import Helmet from "./Helmet";
function OurSubBrands() {

  const gallerySettings = {
    arrow: true,
    dots: false,
    infinite: true,
    speed: 500,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          centerMode: false,
        },
      },
    ],
  };

  const pageCode = "HOME";
  const pagesSectionData = useQuery(["pagesSectionData", pageCode], () =>
    getPageData(pageCode)
  );

  const firstIndexLogoData = pagesSectionData &&
    pagesSectionData.data &&
    pagesSectionData?.data?.data?.map((sectionData) => {
      const ourBrandsSection = sectionData && sectionData.section_details.find(item => item.section_code === "OURBRANDS");

      if (ourBrandsSection && ourBrandsSection.subSectionData) {
        const subDataArray = ourBrandsSection.subSectionData.flatMap((subData) => {
          return subData && subData.elements.filter(
            (items) => items.field_title_code === "UPLOADLOGO"
          );
        });

        const filteredSubData = subDataArray.filter((filteredSubData) => filteredSubData.length !== 0);

        return filteredSubData.length > 0 ? filteredSubData[0].data : undefined;
      }

      return undefined;
    });
    const firstIndexLogoDataImgAltTag = pagesSectionData &&
    pagesSectionData.data &&
    pagesSectionData.data.data.map((sectionData) => {
      const ourBrandsSection = sectionData && sectionData.section_details.find(item => item.section_code === "OURBRANDS");

      if (ourBrandsSection && ourBrandsSection.subSectionData) {
        const subDataArray = ourBrandsSection.subSectionData.flatMap((subData) => {
          return subData && subData.elements.filter(
            (items) => items.field_title_code === "UPLOADLOGO"
          );
        });

        const filteredSubData = subDataArray.filter((filteredSubData) => filteredSubData.length !== 0);

        return filteredSubData.length > 0 ? filteredSubData[0].img_alt_tag : undefined;
      }

      return undefined;
    });

    
  const secondLogoData = pagesSectionData &&
    pagesSectionData.data &&
    pagesSectionData?.data?.data?.map((sectionData) => {
      const ourBrandsSection = sectionData && sectionData.section_details.find(item => item.section_code === "OURBRANDS");

      if (ourBrandsSection && ourBrandsSection.subSectionData) {
        const subDataArray = ourBrandsSection.subSectionData.flatMap((subData) => {
          return subData && subData.elements.filter(
            (items) => items.field_title_code === "UPLOADLOGO"
          );
        });

        const filteredSubData = subDataArray.filter((filteredSubData) => filteredSubData.length !== 0);

        return filteredSubData.length > 0 ? filteredSubData[1].data : undefined;
      }

      return undefined;
    });
    const keywords = pagesSectionData?.data?.data[0]?.meta_keyword;
    const slug = pagesSectionData?.data?.data[0]?.slug;
    const meta_title = pagesSectionData?.data?.data[0]?.meta_title;
    const meta_description = pagesSectionData?.data?.data[0]?.meta_description;

    const secondLogoDataImgAltTag = pagesSectionData &&
    pagesSectionData.data &&
    pagesSectionData.data.data.map((sectionData) => {
      const ourBrandsSection = sectionData && sectionData.section_details.find(item => item.section_code === "OURBRANDS");

      if (ourBrandsSection && ourBrandsSection.subSectionData) {
        const subDataArray = ourBrandsSection.subSectionData.flatMap((subData) => {
          return subData && subData.elements.filter(
            (items) => items.field_title_code === "UPLOADLOGO"
          );
        });

        const filteredSubData = subDataArray.filter((filteredSubData) => filteredSubData.length !== 0);

        return filteredSubData.length > 0 ? filteredSubData[1].img_alt_tag : undefined;
      }

      return undefined;
    });
  return (
    <>
    <Helmet
        keywords={keywords}
        slug={slug}
        metaDescription={meta_description}
        title={meta_title}
      />
      <section>
        <div className="our-sub-brands section-padding pt-0">
          <div className="container">
            {/* /row */}
            <div className="row justify-content-center position-relative d-none d-md-flex">
              <div className="vertically-heading h-100 position-absolute text-nowrap me-auto m-0">
                {pagesSectionData &&
                  pagesSectionData.data &&
                  pagesSectionData?.data?.data?.map((sectionData, index) => {
                    return (
                      <h2 className="text-uppercase georgia heading-space h1" key={index}>
                        {sectionData &&
                          sectionData.section_details
                            .filter(
                              (item) => item.section_code === "OURBRANDS"
                            )[0]
                            .section_elements.filter(
                              (item) => item.field_label_code === "TITLE"
                            )[0].data}
                      </h2>
                    );
                  })}
              </div>
              <div className="col-md-12">
                <div className="row gy-4">
                  <div className="col-xl-4 col-md-6">
                    <div className="sub-brand-card d-flex align-items-center justify-content-center w-100 h-100 rounded-4">
                      <img
                        src={firstIndexLogoData}
                        alt={firstIndexLogoDataImgAltTag}
                        title=""
                      />
                    </div>
                  </div>
                  {pagesSectionData &&
                    pagesSectionData.data &&
                    pagesSectionData?.data?.data?.map((sectionData) => {
                      return (
                        sectionData &&
                        sectionData.section_details
                          .filter(
                            (item) => item.section_code === "OURBRANDS"
                          )[0]
                          .subSectionData.filter(
                            (logoData) =>
                              logoData.field_title_code !== "UPLOADLOGO"
                          )
                          .map((subData, index) => {
                            if (subData && subData.elements) {
                              const featureImage = subData.elements.filter(
                                (items) => items.field_title_code === "IMAGE"
                              )[0]?.data;

                              const featureImageAltTag = subData.elements.filter(
                                (items) => items.field_title_code === "IMAGE"
                              )[0]?.img_alt_tag;
                           

                              const cardHeading = subData.elements.filter(
                                (items) => items.field_title_code === "HEADING"
                              )[0]?.data;

                              const cardParagraph = subData.elements.filter(
                                (items) => items.field_title_code === "CONTENT"
                              )[0]?.data;

                              const cardUrl = subData.elements.filter(
                                (items) =>
                                  items.field_title_code === "DISCOVERBUTTON"
                              )[0]?.data;

                              if (
                                featureImage !== undefined &&
                                cardHeading !== undefined &&
                                cardParagraph !== undefined &&
                                cardUrl !== undefined
                              ) {
                                return (
                                  <FeatureCard
                                    key={index}
                                    cardFeatureImage={featureImage}
                                    featureImageAltTag={featureImageAltTag}
                                    cardBg={`cardBg${index + 1}`}
                                    cardHeading={cardHeading}
                                    cardParagraph={cardParagraph}
                                    cardLinkName={"DISCOVER"}
                                    cardUrl={cardUrl}
                                  
                                  />
                                );
                              }
                            }
                            return null; // Skip rendering if the conditions are not met
                          })
                      );
                    })}
                  <div className="col-xl-4 col-md-6">
                    <div className="sub-brand-card d-flex align-items-center justify-content-center w-100 h-100 rounded-4">
                      <img
                        src={secondLogoData}
                        alt={secondLogoDataImgAltTag}
                        title=""
                      />
                    </div>
                  </div>
                </div>
                {/* /row */}
              </div>
              {/* /col-md-12 */}
            </div>
            {/* /row */}

            <div className="brands-slide d-md-none">
              <div className="vertically-heading mb-2">
                {pagesSectionData &&
                  pagesSectionData.data &&
                  pagesSectionData?.data?.data?.map((sectionData, index) => {
                    return (
                      <h2 className="text-uppercase georgia heading-space h1" key={index}>
                        {sectionData &&
                          sectionData.section_details
                            .filter(
                              (item) => item.section_code === "OURBRANDS"
                            )[0]
                            .section_elements.filter(
                              (item) => item.field_label_code === "TITLE"
                            )[0].data}
                      </h2>
                    );
                  })}
              </div>
              <SubBrandsSlides
                gallerySettings={gallerySettings}
                pagesSectionData={pagesSectionData}
                cardBg={`cardBg`}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default OurSubBrands;
