import React,{useContext,useState} from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";

import innerCover from "../../assets/images/offers-cover.jpg"
import parse from "html-react-parser"



import { Link, useParams } from "react-router-dom";

import { getSingleOfferData } from "../../services/dataServices";
import { useQuery } from "react-query";
import Helmet from "../../components/Helmet";
import PageNotFound from "../../components/PageNotFound";
import { MyContext } from "../../Contex/LoadingContex"
import Loader from "../../components/Loader";
import BannerImage from "../../components/BannerImage";
import Image from "../../components/Image";
const OfferDetailPage = ({setErrorPage,slugOfOffers}) => {


  const { offerId } = useParams();

  let singleOfferData = useQuery(["getSingleOfferData", +offerId], () =>
    getSingleOfferData(offerId)
  );
  const {isLoading,isSuccess}=singleOfferData
  const { isLoadingMain, setIsLoadingMain } = useContext(MyContext);
  let keywords=singleOfferData?.data?.data?.keywords
  let slug=  singleOfferData?.data?.data?.slug
  let meta_title=singleOfferData?.data?.data?.meta_title
  let meta_description= singleOfferData?.data?.data?.meta_description
  let innerImage= singleOfferData?.data?.data?.inner_banner_image

  if(singleOfferData?.data?.data===404){
  
    return(
      <>
        <PageNotFound setErrorPage={setErrorPage}/>
      </>
    )
  }
 const bannerImage= singleOfferData?.data?.data?.inner_banner_image?singleOfferData?.data?.data?.inner_banner_image:innerCover
 const bannerImageAltTag = singleOfferData?.data?.data?.inner_banner_alt_tag
 return (
    <>
     <Helmet keywords={keywords} slug={slug} metaDescription={meta_description} title={meta_title}/>
      <section>
        <div className="inner-cover header-space position-relative">
          <div className="our-story position-absolute start-0 top-0 h-100">
            {/* <img
              className="w-100 h-100"

              src={}
              onLoad={handleImageLoad}
              onError={handleError}
              title=""
              alt={singleOfferData?.data?.data?.inner_banner_alt_tag}

            /> */}
             <BannerImage
              className="w-100 h-100"
              src={bannerImage}
              title=""
              alt={bannerImageAltTag}
              isDataIsLoading={isLoading}
            />
          </div>
          <div className="page-inner position-relative text-center">
            <div className="page-title d-flex justify-content-center">
              <h2 className="text-uppercase text-white georgia py-xl-4 py-3 px-5">
                OFFERS
              </h2>
            </div>
            <Breadcrumb className="d-flex justify-content-center mt-4 text-white">
              <Breadcrumb.Item>
                <Link to="/">Home</Link>{" "}
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/${slugOfOffers}`}>Offers</Link>{" "}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                {singleOfferData?.data?.data?.offer_name}
                {/* Book Direct – Lowest Price Assurance */}
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </section>

      <section>
        <div className="offer-details section-padding">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="small-heading text-center mb-4">
                  {/* <span style={{ textTransform: 'uppercase' }} > {(singleOfferData?.data?.data?.resort_name)} | MALDIVES</span> */}
                  <span className="text-uppercase" > {(singleOfferData?.data?.data?.resort_name)} | MALDIVES</span>
                </div>
                <div class="dashed-heading position-relative">
                  <h2 class="georgia text-uppercase text-center mb-0">
                    <span className="px-4"> {singleOfferData?.data?.data?.offer_name}</span>
                  </h2>
                </div>
                <h2 class="georgia text-uppercase text-center mb-0 mt-4 fw-bold">
                  {/* Book Direct – Lowest Price Assurance */}
                  {/* {parseInt(singleOfferData?.data?.data?.discount_percent)}% OFF */}
                  {(singleOfferData?.data?.data?.discount_text)}

                </h2>
              </div>
              <div className="row mt-lg-5 mt-4">
                <div className="col-12">
                  <div className="large-img-offer">
                    {/* <img
                      className="w-100"
                      src={singleOfferData?.data?.data?.offer_image}
                      alt={singleOfferData?.data?.data?.offer_image_alt_tag}

                      title=""

                    /> */}
                    <Image
                      className="w-100"
                      src={singleOfferData?.data?.data?.offer_image}
                      alt={singleOfferData?.data?.data?.offer_image_alt_tag}

                      title=""

                    />
                  </div>
                  <div className="offer-para mt-4 pt-2">
                    <p>{parse(`${singleOfferData?.data?.data?.description}`)}
                      {/* Save 20% on a beautiful picture-postcard holiday in the
                      pristine Lhaviyani Atoll. With assured all-day-long
                      sunshine and clear blue skies of the high season, spend
                      memorable moments with your loved ones. Immerse in the
                      natural beauty and luxurious experiences of Atmosphere
                      Kanifushi. */}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mt-lg-5 mt-4">
                <div className="col-lg-6 pe-lg-4">
                  <div class="dashed-heading position-relative">
                    <h4 class="georgia text-uppercase mb-0">
                      <span className="pe-3">HIGHLIGHTS</span>
                    </h4>
                  </div>
                  <div className="offer-points mt-lg-4 mt-3">
                    <ul className="m-0">
                    {singleOfferData?.data?.data?.highlights.
                      split("\n").map((item) => (
                        <li>
                          <span>{item}</span>
                        </li>
                      ))}
                      {/* <li>
                        <span>20% discount on Booking</span>
                      </li>
                      <li>
                        <span>
                          All-Inclusive Kanifushi Plan<sup>™</sup> inclusions
                        </span>
                      </li>
                      <li>
                        <span>Complimentary Roundtrip transfers</span>
                      </li>
                      <li>
                        <span>
                          Dine Around Options for a Gastronomic Adventure
                        </span>
                      </li>
                      <li>
                        <span>
                          Experiences Inclusive with Free Snorkeling & Water
                          Sports
                        </span>
                      </li> */}
                    </ul>
                  </div>
                </div>
                <div className="col-lg-6 ps-lg-4 mt-lg-0 mt-md-5 mt-4">
                  <div class="dashed-heading position-relative">
                    <h4 class="georgia text-uppercase mb-0">
                      <span className="pe-3">TERMS & CONDITIONS</span>
                    </h4>
                  </div>
                  <div className="offer-points mt-lg-4 mt-3">
                    <ul className="m-0">
                      {singleOfferData?.data?.data?.terms_conditions.split('\n').map(item => (
                        <li>
                          <span>{item}</span>
                        </li>
                      ))}
                      {/* <li>
                        <span>*Subject to availability</span>
                      </li>
                      <li>
                        <span>Minimum 4 nights stay</span>
                      </li>
                      <li>
                        <span>For Stays Until 31st October 2024</span>
                      </li>
                      <li>
                        <span>
                          This offer cannot be combined with any other special
                          offer
                        </span>
                      </li>
                      <li>
                        <span>
                          Standard cancelations and payments as per resort
                          policy
                        </span>
                      </li> */}
                    </ul>
                  </div>
                </div>
                <div className="col-12 mt-xl-5 mt-4">
                  <div className="offer-book-action">
                    <a href={singleOfferData?.data?.data?.website_url}
                      target="_blank"
                      className="btn primary theme-btn fw-medium text-white text-uppercase border-theme btn btn-primary book_now_offers_ga4"
                    // onClick={handleShow}
                    >
                      Book Now
                    </a>
                    {/* <Modals show={show} handleClose={handleClose} /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OfferDetailPage;
